"use strict";

let animations = [];
let isScrollHandlerRunning = false;
export function add($elements, calculateAnimationProgress, setAnimationProgress) {
    animations = animations.concat($elements.toArray().map(element => ({
        element: element,
        calculateAnimationProgress,
        setAnimationProgress
    })));

    if (!isScrollHandlerRunning && !!animations.length) {
        isScrollHandlerRunning = true;
        requestAnimationFrame(scrollHandler);
    }
}

let lastScrollPosition = null;
function scrollHandler() {
    if (window.pageYOffset === lastScrollPosition) {
        requestAnimationFrame(scrollHandler);
        return;
    }

    lastScrollPosition = window.pageYOffset;

    animations.map(({element, calculateAnimationProgress, setAnimationProgress}) => ({
        element,
        progress: calculateAnimationProgress(element),
        setAnimationProgress
    })).forEach(({element, progress, setAnimationProgress}) => {
        setAnimationProgress(element, progress);
    });

    requestAnimationFrame(scrollHandler);
}
